const Forms = {
    data() {
        return {
            isAuthenticated: false
        }
    },
    created() {
        if ($('.login-page').length > 0) {
            this.isAuthenticated = false;
        } else {
            this.isAuthenticated = true;

            this.getQuizData();
        }
    },
    methods: {
        clearForm() {
            this.restorePasswordEmailInput = '';

            const $input = document.getElementById('password-recovery');
            $input.value = '';
            if ($input.value === '' || $input.value.length === 0) {
                $input.nextElementSibling.removeAttribute('style');
            }
        }
    }
}