const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` }
}

const loggedIn = localStorage.getItem('loggedIn');

const Login = {
    data() {
        return {
            usernameInput: '',
            passwordInput: '',
            isBusy: false
        }
    },
    created() {
        document.addEventListener('keyup', this.loginAction)
    },
    destroyed() {
        document.removeEventListener('keyup', this.loginAction)
    },
    methods: {        
        login() {
            this.isBusy = true;
            this.userAlert = false;
            this.userAlertMessages = [];

            const params = {
                username: this.usernameInput,
                password: this.passwordInput
            }
            if(this.usernameInput == '' && this.passwordInput == '') {
                this.isBusy = false;
                this.userAlert = true;
                this.userAlertMessages.push('Oba pola są wymagane')
                return false
            } else {
                this.userAlert = false
                this.userAlertMessages = []
                axios.post('/check_login', params, config)
                    .then(request => {
                        if(this.userAlert) {
                            this.userAlert = false
                        }
                        this.loginSuccessful(request)
                    })
                    .catch((error) => {
                        this.isBusy = false;
                        this.userAlert = true;
                        this.loginFailed(error.response)
                    })
            }
        },
        loginAction() {
            if(this.usernameInput.length > 0 && this.passwordInput.length > 0) {
                this.userAlert = false
                this.userAlertMessages = []
            }
        },
        loginSuccessful(req) {
            localStorage.token = req.data[0].token
            localStorage.setItem('loggedIn', true)
            return window.location.replace(window.location.origin || '/prezentacja');
        },
        loginFailed(error) {
            this.userAlert = true
            this.userAlertMessages = []

            if(error.data.username) {
                this.userAlertMessages.push('Konto Użytkownika nie istnieje lub nie zostało aktywowane')
            }
            if(error.data.password) {
                this.userAlertMessages.push('Błędna nazwa użytkownika lub hasło')
            }

            localStorage.clear();
            localStorage.removeItem('loggedIn')
            delete localStorage.token
        },  
        logout() {
            localStorage.clear();
            localStorage.removeItem('loggedIn')
            delete localStorage.token
        }   
    }
}