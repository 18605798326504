const Modals = {
    computed: {
        escapeableVisible() {
            return this.restorePasswordModal || /* this.surveyOpen  || */ this.surveyCompleted;
        },        
    },
    methods: {
        closeModal() {
            if (this.restorePasswordModal === true) {
                this.recoveryPasswordAlert = false,
                this.recoveryPasswordAlertMessage = '',
                this.restorePasswordEmailInput = '';
                this.handleRestorePassword();
                this.clearForm();
            }
            // if(this.surveyOpen) {
            //     this.handleOpenSurvey();
            // }
            // if(this.surveyCompleted) {
            //     this.handleSurveyComplete();
            // }
        },
        escapeHandler() {
            if(this.restorePasswordModal) {
                this.recoveryPasswordAlert = false,
                this.recoveryPasswordAlertMessage = '',
                this.restorePasswordEmailInput = '';
                this.handleRestorePassword();
                this.clearForm();
            }
            // if(this.surveyOpen) {
            //     this.handleOpenSurvey();
            // }
            // if(this.surveyCompleted) {
            //     this.handleSurveyComplete();
            // }
        }, 
    }
}