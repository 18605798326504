const PasswordRecovery = {
    data() {
        return {
            restorePasswordModal: false,
            restorePasswordEmailInput: '',
            recoveryPasswordAlert: false,
            recoveryPasswordAlertMessage: '',
            recoverySent: false,
            resetLoadingSubmit: false
        }
    },
    created() {
        document.addEventListener('keyup', this.checkRecoveryInput)
    },
    destroyed() {
        document.removeEventListener('keyup', this.checkRecoveryInput)
    },
    methods: {
        handleRestorePassword() {
            this.userAlert = false
            this.userAlertMessages = []
            this.restorePasswordModal = !this.restorePasswordModal;

            if (this.restorePasswordModal) {
                document.body.classList.add('open-modal');
            } else {
                document.body.classList.remove('open-modal');
            }
        },        
        restorePasswordInputHandle(event) {
            this.restorePasswordEmailInput = event.target.value;            
        },
        submitPasswordRecovery() {
            this.resetLoadingSubmit = true;
            this.recoveryPasswordAlert = false,
            this.recoveryPasswordAlertMessage = ''
            const params = {
                username: this.restorePasswordEmailInput
            };

            if(this.restorePasswordEmailInput == '') {
                this.resetLoadingSubmit = false
                this.recoveryPasswordAlert = true
                this.recoveryPasswordAlertMessage = 'Pole wymagane'
                return false
            } else {
                this.recoveryPasswordAlert = false,
                this.recoveryPasswordAlertMessage = ''
                axios.post('/resetuj/send-email', params)
                    .then((response) => {
                        if(this.userAlert) {
                            this.recoveryPasswordAlert = false
                        }
    
                        this.recoveryPasswordSuccess();
                    })
                    .catch((error) => {
                        this.resetLoadingSubmit = false;
                        this.recoveryPasswordAlert = true;
                        this.recoveryPasswordFailure(error.response)
                    })
            }

        },
        checkRecoveryInput() {
            if(this.restorePasswordEmailInput.length > 0) {
                this.recoveryPasswordAlert = false,
                this.recoveryPasswordAlertMessage = ''
            }
        },
        recoveryPasswordSuccess() {
            this.recoverySent = true;                        
        },
        recoveryPasswordFailure(error) {
            this.recoveryPasswordAlert = true
            this.recoveryPasswordAlertMessage = ''

            const message = error.data;

            switch(message) {
                case 'User dont exist':
                    this.recoveryPasswordAlertMessage = 'Użytkownik nie istnieje'
                    break;
                case 'You can change password after 48 hours':
                    this.recoveryPasswordAlertMessage = 'Hasło można zmienić za 48 godzin'
                    break;
                default: 
                    return false
            }

            setTimeout(() => {
                this.recoveryPasswordAlert = false
                this.recoveryPasswordAlertMessage = ''
            }, 5000)

            this.clearForm();
        },
    }
}