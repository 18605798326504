const QuizQuestions = Vue.component('question', {
    template:
        `
        <div class="quiz-container" :key="question.id">            
                <div class="quiz-question">
                    <h2>
                        <span id="index">{{ number }}.</span>
                        <span id="question">{{ question.question }}</span>
                    </h2>
                </div>
            <div class="quiz-answers">
                <ul>
                    <li v-for="(res, index) in question.answers" :key="res.id" @click="submitAnswer(res)">
                        <span class="radio-button"></span>
                        <span class="radio-text">{{ res.name }}</span>
                    </li>                
                </ul>
            </div>
            <slot></slot>
        </div>
    `,
    props: ['question', 'question-number', 'number'],
    data() {
        return {
            answer: []
        }
    },
    methods: {
        submitAnswer(target) {
            this.answer = target;
            this.$emit('answer', {r: this.answer});
        }
    }
});