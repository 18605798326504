'use strict';

var textOrphans = function () {
    $('p').each(function () {

        if ($(this).html().match(/class="order"/g)) return;

        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
};

var cookies = function () {
    if ($.cookie('giodoCookies') !== '1') {
        $('.cookies-alert').css('display', 'block');
    }
    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').slideToggle('slow', function () {
            $.cookie('giodoCookies', '1', {
                expires: 365
            });
        });
    });
};

var player = function () {
    // https://github.com/sampotts/plyr
    // Player for #player initialized    

    var controls, player;

    if($('.home-video').length > 0){
        player = new Plyr('.player', {
            autoplay: false,
            clickToPlay: false,
            controls: controls
        });
    } else {
        controls = ['play-large', 'fullscreen'];
        player = new Plyr('.player', {
            autoplay: false,
            clickToPlay: true,
            controls: controls
        });
    }

    player.source = {
        type: 'video',
        title: 'Akademia Silimax', 
        sources: [
            {
                src: '586629060',
                provider: 'vimeo'
            },
        ],
    };

    player.on('ended', function (event) {

        const authorized = config;

        if (loggedIn) {
            axios.get('/api/user/show-stage-first', authorized)
                .then((r) => {
                    $('#next-stage-button').removeClass('disabled');
                    $('.stage-link[data-stage="quiz"]').css({
                        'pointer-events': 'all'
                    });
                })
                .catch((e) => {
                    
                })
        }

    });
}

var customScrollbar = function () {
    // scoreboard-list
    $('#scoreboard-list').customScrollbar({
        skin: "default-skin",
        hScroll: true,
        vScroll: true,
        wheelSpeed: 25,
        updateOnWindowResize: true,
        animationSpeed: 1000,
        preventDefaultScroll: true
    });
}

var loadRegulations = function() {
    const RegulationsDocument = $('.regulation_document');

    axios.get('/regulations')
        .then((response) => {              
            if(response.data !== null) {
                setTimeout(() => {
                    if (response.status === 200 && response.data.announced === true) {
                        RegulationsDocument.attr('href', response.data.file)
                    } else {
                        RegulationsDocument.attr('href', null)
                    }
                }, 1000)                     
            }
        })
        .catch((r) => { })

    return RegulationsDocument;
}

$(window).on('resize', function () {
    player();
});

$(window).on('load', function () {
    setTimeout(() => {
        player();
    }, 1000)
})

$(document).on('focusout', 'input', function () {
    var input = $(this);

    if (input.val().length > 0) {
        input.siblings('label').css({
            'opacity': 0,
            'transform': 'translateX(20px)'
        });
    } else {
        input.siblings('label').removeAttr('style');
    }
});

$(document).on('focusout', '#fos_user_resetting_form input', function () {
    var input = $(this);

    if (input.val().length > 0) {
        input.siblings('label').removeAttr('style');
    }
});


$(document).on('focusout', 'textarea', function () {
    var textarea = $(this);
    if (textarea.val().length > 0) {
        textarea.siblings('label').css({
            'opacity': 0,
            'transform': 'translateX(20px)'
        })
    } else {
        textarea.siblings('label').removeAttr('style');
    }

    if (textarea.hasClass('individual_textarea')) {
        $('textarea.individual_textarea').siblings('label').removeAttr('style');
    }
});

$(document).on('click', '.input-checkbox', function () {
    $(this).removeAttr('checked');

    if ($(this).is(':checked')) {
        $(this).attr('checked', true);
    }
});

$(window).on('load', function () {
    customScrollbar();
})

$(function () {
    cookies();
    textOrphans();
    player();
    loadRegulations();
})