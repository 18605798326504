const Timer = {
    data() {
        return {
            hours: "00",
            minutes: "00",
            seconds: "00",
            milliseconds: "00",
            timerText: "",
            interval: null,
            intervalMs: null
        }
    },
    computed: {
        stopwatchTimer() {      
            if(this.hours > 0) {
                this.timerText = `${this.hours}:${this.minutes}:${this.seconds}`
            } else if(this.minutes > 0) {
                this.timerText = `${this.minutes}:${this.seconds}`
            }
            else {
                this.timerText = `${this.seconds} s`
            }      

            return this.timerText;
        },
        
    },
    methods: {
        padLeft(nr, n, str) {
            return Array(n-String(nr).length+1).join(str||'0')+nr;
        },
        toInt() {
            this.hours = parseInt(this.hours);
            this.minutes = parseInt(this.minutes);
            this.seconds = parseInt(this.seconds);
            this.milliseconds = parseInt(this.milliseconds);
        },
        padded() {
            this.hours = this.padLeft(this.hours, 2);
            this.minutes = this.padLeft(this.minutes, 2);
            this.milliseconds = this.padLeft(this.milliseconds, 2);
        },        
        stopwatchStart() {
            this.interval = setInterval(() => {
                this.toInt();
                this.seconds += 1;

                if(this.minutes === 60) {
                    this.seconds = 0;
                    this.minutes = 0;
                    this.hours += 1;
                }
                if(this.seconds === 60) {
                    this.seconds = 0;
                    this.minutes += 1;
                }

                this.padded();                
            }, 1000);

            this.intervalMs = setInterval(() => {
                this.toInt();
                this.milliseconds += 1;

                if(this.milliseconds === 100) {
                    this.milliseconds = 0;
                }

                this.padded();
            }, 10)
        }
    }
}