const Register = {
    data() {
        return {
            createUsername: '',
            createFirstname: '',
            createLastname: '',
            createPhone: '',
            createPassword: '',
            createPasswordConfirm: '',
            createEmail: '',
            // createAddress: '',
            createShippingAddress: '',
            createRegulation: false,
            createFirstMarketing: false,
            createSecondMarketing: false,
            createThirdMarketing: false,
            createFourthMarketing: false,
            accountActivateRequired: false,
            createPasswordError: false,
            createPasswordErrorText: '',
            isAgreementExpand: false,
            // firstMarketingExpandDisplay: false,
            // secondMarketingExpandDisplay: false,
            // thirdMarketingExpandDisplay: false,
            // agreementInnerExpand: false,
            registerNotAllowed: true,
            isFarmacist: false,
            isClauseExpand: false
        }
    },
    created() {
        document.addEventListener('keyup', this.createAccountValidate)
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.createAccountValidate)
    },
    methods: {
        userRegistration() {
            this.isBusy = true;
            this.userAlert = false;
            this.userAlertMessages = [];

            let response = {
                username: this.createUsername,
                firstname: this.createFirstname,
                lastname: this.createLastname,
                phone: this.createPhone,
                email: this.createEmail,
                password: this.createPassword == this.createPasswordConfirm,
                // address: this.createAddress,
                shippingAddress: this.createShippingAddress,
                regulation: this.createRegulation,
                firstMarketing: this.createFirstMarketing,
                secondMarketing: this.createSecondMarketing,
                thirdMarketing: this.createThirdMarketing,
                fourthMarketing: this.createFourthMarketing,
                farmacist: this.isFarmacist
            }
            const reg = /^[a-z\d]+[\w\d.-]*@(?:[a-z\d]+[a-z\d-]+\.){1,5}[a-z]{2,6}$/i;

            if (this.createPassword === this.createPasswordConfirm && 
                this.createUsername !== '' && 
                this.createFirstname !== '' && 
                this.createLastname !== '' && 
                this.createPhone.length === 9 &&
                reg.test(this.createEmail) === true &&
                this.createShippingAddress !== '' && 
                this.createRegulation !== false) {

                response.password = this.createPassword;
                this.createPasswordError = false;
                this.createPasswordErrorText = '';

                this.registerSubmit(response);
            } else {
                response.password = '';
                this.isBusy = false;
                return false;
            }
        },
        validateCheckbox() {
            if(this.createRegulation && this.isFarmacist) {
                this.registerNotAllowed = false;
            } else {
                this.registerNotAllowed = true;
            }

        },
        registerSubmit(response) {
            axios.post('/register-user', response)
            .then((res) => {
                if (this.userAlert) {
                    this.userAlert = false
                }

                this.isBusy = false;
                this.userRegistrationSuccess()
            })
            .catch((error) => {
                this.userRegistrationFailed(error.response)
            })
        },
        userRegistrationSuccess() {
            this.createUsername = ''
            this.createFirstname = ''
            this.createLastname = ''
            this.createPhone = ''
            this.createPassword = ''
            this.createPasswordConfirm = ''
            this.createEmail = ''
            // this.createAddress = ''
            this.createShippingAddress = ''
            this.createRegulation = false
            this.createFirstMarketing = false
            this.createSecondMarketing = false,
            this.createThirdMarketing = false,
            this.createFourthMarketing = false,
            this.isFarmacist = false;

            // User activate account information
            this.accountActivateRequired = true
        },
        userRegistrationFailed(error) {
            this.isBusy = false
            this.userAlert = true
            if (error.data.email) {
                this.userAlertMessages.push('Niepoprawny format adresu e-mail.')
            }
            else {
                this.userAlertMessages.push('Użytkownik o podanym adresie e-mail lub nazwie użytkownika już istnieje.')
            }
        },
        numberValidate($event) {            
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }

            if(this.createPhone.length >= 9) {
                $event.preventDefault();
                return false;
            }
        },
        password() {
            const passwordConfirm = document.getElementById('confirm_password');

            if (this.createPassword.length >= 5) {
                passwordConfirm.removeAttribute('disabled')
            } else {
                passwordConfirm.setAttribute('disabled', true)
            }

            if (this.createPassword.length < 5 && this.createPasswordConfirm.length > 0) {
                this.createPassword;
                this.createPasswordError = false;
            }

            if(this.createPassword === '' && this.createPasswordConfirm === '') {
                this.createPassword;
                this.createPasswordError = false;
                this.createPasswordErrorText = '';
            }
        },
        passwordValidate() {
            if (this.createPassword === '') {
                this.createPassword;
                this.createPasswordError = false;
                this.createPasswordErrorText = '';
            }

            if (this.createPasswordConfirm === this.createPassword) {
                this.createPassword;
                this.createPasswordError = false;
                this.createPasswordErrorText = '';

                if (this.createPassword === '' || this.createPasswordConfirm === '') {
                    this.createPassword;
                    this.createPasswordError = false;
                    this.createPasswordErrorText = '';
                }
            } else {
                this.createPasswordError = true;
                this.createPasswordErrorText = 'Hasła się różnią.'
                return false;
            }
        },
        createAccountValidate() {
            if (this.createPassword === '' || this.createPasswordConfirm === '') {
                this.createPassword;
                this.createPasswordError = false;
                this.createPasswordErrorText = '';
            }
        },
        clauseInfoExpand() {
            this.isClauseExpand = !this.isClauseExpand;
        },

        // expandAgreement() {
        //     this.isAgreementExpand = !this.isAgreementExpand
        // },

        // firstMarketingExpand() {
        //     this.firstMarketingExpandDisplay = !this.firstMarketingExpandDisplay
        // },
        // secondMarketingExpand() {
        //     this.secondMarketingExpandDisplay = !this.secondMarketingExpandDisplay
        // },
        // thirdMarketingExpand() {
        //     this.thirdMarketingExpandDisplay = !this.thirdMarketingExpandDisplay
        // },
        // agreementInner() {
        //     this.agreementInnerExpand = !this.agreementInnerExpand
        // }
    }
}