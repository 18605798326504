const token = localStorage.token;
if(token) {
    axios.defaults.headers.common['Authorization'] = token
}

var vm = new Vue({
    mixins: [
        Escapable, Timer, Menu,
        Login, Register, PasswordRecovery,
        Modals, Forms, EditProfileForm, userAlerts
    ],
    data() {
        return {
            desktop: true,

            questions: [],
            randomQuestions: [],
            currentQuestion: 0,
            answers: [],
            correct: 0,
            questionNumber: 1,
            progress: 0,
            totalScore: 0,
            isCompleted: false,

            countdown: 5,

            random: 0,
            responses: [],            
        }
    },
    created() {        
        const isAuth = document.getElementById('user-auth');

        if(this.countdown === 0) {
            clearTimeout(this.redirectCountdown());
        }        

        if(isAuth) {
            this.authorized(isAuth.dataset.authorized);                
        }

    },
    computed: {
        progressBarStyled() {
            return {
                width: `${this.progress}%`
            }
        },
        redirectCountdown() {
            if(this.countdown > 0) {
                setTimeout(() => {
                    this.countdown -= 1
                }, 1000)
            } else {
                return window.location.replace(window.location.origin + '/szkolenie/wynik');
            }

            return `${this.countdown}`;
        },
    },
    methods: {        
        authorized(userToken) {
            localStorage.token = userToken
            axios.defaults.headers.common['Authorization'] = userToken
            if(userToken) {
                localStorage.setItem('loggedIn', true)
            }
        },
        // QUIZ API
        randomList(array) {
            var currentIndex = array.length;            
            var temporaryValue;
            var randomIndex;            
            var myRandomizedList;        
            // Clone the original array into myRandomizedList (shallow copy of array)
            myRandomizedList = array.slice(0)
    
            // Randomize elements within the myRandomizedList - the shallow copy of original array
            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
    
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
    
                // And swap it with the current element.
                temporaryValue = myRandomizedList[currentIndex];
                myRandomizedList[currentIndex] = myRandomizedList[randomIndex];
                myRandomizedList[randomIndex] = temporaryValue;        
            }

            this.random = myRandomizedList[this.currentQuestion];
                
            var currentIndexAnswers = this.random.answers.length;
            var temporaryValueAnswers;
            var randomIndexAnswers;        
            var myRandomizedAnswers;

            myRandomizedAnswers = myRandomizedList[this.currentQuestion].answers.slice(0);
            
            while (0 !== currentIndexAnswers) {
                randomIndexAnswers = Math.floor(Math.random() * currentIndexAnswers);
                currentIndexAnswers -= 1;

                temporaryValueAnswers = myRandomizedAnswers[currentIndexAnswers];
                myRandomizedAnswers[currentIndexAnswers] = myRandomizedAnswers[randomIndexAnswers];
                myRandomizedAnswers[randomIndexAnswers] = temporaryValueAnswers;
            }

            // Append Total Score
            this.totalScore = array.length;

            // Set progress e.g "1/10"
            this.progress = ((this.questionNumber / this.totalScore) * 100).toFixed(2);

            // Return the new array that has been randomized
            this.responses = myRandomizedAnswers;
            myRandomizedList[this.currentQuestion].answers = this.responses;

            return myRandomizedList;
        },
        getQuizData() {
            if(token && loggedIn) {
                axios.get('/api/questions', config)
                    .then((r) => {
                        this.randomQuestions = this.randomList(r.data);
    
                        this.stopwatchStart();
                        this.questionsTotal = r.data.length;
                    })
                    .catch((e) => {});
            }
        },
        quizResultsPOST(results) {
            axios.post('/api/user/show-stage-second', results, config)
                .then((res) => {})
                .catch((err) => {})
        },
        handleAnswer(e) {
            this.answers[this.currentQuestion] = e.r;

            if ((this.currentQuestion + 1) === this.randomQuestions.length) {
                this.handleResults();
                this.questionNumber = this.randomQuestions.length;
            } else {
                this.currentQuestion++;
                this.questionNumber++;
                this.progress = ((this.questionNumber / this.totalScore) * 100).toFixed(2)
            }
        },
        handleResults() {
            this.isCompleted = true;
            this.randomQuestions = [];
            
            let Score = [];
            let CurrentDate = new Date();
            let DateCompleted = '';
            let TimeCompleted = '';
            let DateAndTimeCompleted = '';
            let Milliseconds = '';
            let Results = [];

            const Answers = this.answers;

            Answers.forEach((a, i) => {
                if (a.correct) {
                    this.correct++;
                }
            })

            Score = this.correct;
            DateCompleted = this.handleDateFormat(CurrentDate);
            TimeCompleted = this.hours+':'+this.minutes+':'+this.seconds
            DateAndTimeCompleted = DateCompleted+ ' ' +TimeCompleted;
            Milliseconds = this.milliseconds;           

            Results = {
                count_point: Score,
                time_stage: DateAndTimeCompleted,
                time_ms_stage: Milliseconds
            }
    
            clearInterval(this.interval);
            clearInterval(this.intervalMs);
            
            this.quizResultsPOST(Results);
        },
        handleDateFormat(d) {
            function ISODateString(date){
                function pad(n){return n<10 ? '0'+n : n}
                return date.getUTCFullYear()+'-'
                + pad(date.getUTCMonth()+1)+'-'
                + pad(date.getUTCDate())
            }

            return ISODateString(d);                
        },           
    },
})

vm.$mount('#app');