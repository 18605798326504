const Survey = {
    data() {
        return {
            surveyOpen: false,
            questionnaireList: [],
            loadingQuestionnaire: true,
            surveyCompleted: false,
            answer1: '',
            answer2: '',
            answer3: '',
            answer4: '',
            answer5: '',
            answer6: '',
        }
    },
    methods: {
        handleSurveyComplete() {
            this.surveyCompleted = !this.surveyCompleted;

            if (this.surveyCompleted) {
                $('body').addClass('open-modal');
                $('.header-container-dark').addClass('has-modal');
            } else {
                $('body').removeClass('open-modal');
                $('.header-container-dark').removeClass('has-modal');
            }
        },
        handleOpenSurvey() {
            this.surveyOpen = !this.surveyOpen;
            this.handleGetQuestionnaire();

            if (this.surveyOpen) {
                $('body').addClass('open-modal');
                $('.header-container-dark').addClass('has-modal');
            } else {
                $('body').removeClass('open-modal');
                $('.header-container-dark').removeClass('has-modal');
            }
        },
        handleGetQuestionnaire() {
            if (loggedIn) {
                axios.get('/api/questionnaire-questions', config)
                    .then((r) => {
                        this.loadingQuestionnaire = false;
                        this.questionnaireList = r.data
                    })
                    .catch((e) => {})
            }
        },
        handleSubmitQuestionnaire(e) {
            this.isBusy = true;
            this.answer1 = e.target[0].value;
            this.answer2 = e.target[1].value;
            this.answer3 = e.target[2].value;
            this.answer4 = e.target[3].value;
            this.answer5 = e.target[4].value;
            this.answer6 = e.target[5].value;

            const data = {
                answerFirst: this.answer1,
                answerSecond: this.answer2,
                answerThird: this.answer3,
                answerFourth: this.answer4,
                answerFifth: this.answer5,
                answerSixth: this.answer6,
            }

            axios.post('/api/questionnaire', data, config)
                .then((r) => {
                    this.isBusy = false;
                    this.answer1 = '';
                    this.answer2 = '';
                    this.answer3 = '';
                    this.answer4 = '';
                    this.answer5 = '';
                    this.answer6 = '';

                    this.surveyOpen = false;
                    this.loadingQuestionnaire = false;
                    this.questionnaireList = [];
                    this.surveyCompleted = true;
                })
                .catch((error) => {
                    // e.response
                })
        }
    }
};

const QuestionnaireComponent = Vue.component('questionnaire', {
    props: ['open', 'close', 'list', 'loading', 'submit', 'busy'],
    template:
        `
    <div class="modal" @click.self="close">
        <div id="modal-container" class="modal-container modal-container-wide">
            <div class="modal-container-box">
                <div class="modal-container-title">
                    <h2>Ankieta</h2>
                </div>
                <div class="modal-container-text">
                    <p>
                        Jesteś jedną z pierwszych osób, które testują naszą platformę edukacyjną. 
                        Twoja opinia jest dla nas ważna, ponieważ pozwoli nam spojrzeć na serwis z perspektywy użytkownika. 
                        To dla nas wielka szansa na wprowadzenie jeszcze lepszej jakości! Zachęcamy Cię do szczerych wypowiedzi! 
                    </p>
                </div>
                <div class="panel panel-white modal-container-form" v-if="loading">
                    <div class="icon-loading">
                        <span class="icon-loading-dot"></span>
                        <span class="icon-loading-dot"></span>
                        <span class="icon-loading-dot"></span>
                    </div>
                </div>
                <div class="panel panel-white modal-container-form" v-else>
                    <form id="user-questionnaire" class="survey-form" @submit.prevent="submit" data-parsley-validate>
                        <div class="survey-form-body">
                            <div class="survey-form-block" v-for="(item, index) in list" :key="item.id">
                                <label :for="'answer'+(index+1)" class="block-text">{{ item }}</label>
                                <textarea 
                                    type="text" 
                                    class="individual_textarea survey-text" 
                                    :id="'answer'+(index+1)" 
                                    :name="'answer'+(index+1)"
                                    v-on:keydown.enter="$event.stopPropagation()"
                                    required data-parsley-required />
                            </div>                            
                        </div>
                        <div class="survey-form-actions">
                            <button type="submit" class="button button-primary button-primary-loading" v-if="busy" disabled>
                                <div class="button-loading">
                                    <span class="icon-loading-dot"></span>
                                    <span class="icon-loading-dot"></span>
                                    <span class="icon-loading-dot"></span>
                                </div>
                            </button>
                            <button type="submit" class="button button-primary" v-else>Wyślij ankietę</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    `
});