const Menu = {
    data() {
        return {
            triggerMenu: false,
            isEnabled: false,
            filePath: ''
        }
    },
    created() {
        this.listOfWinners();
    },
    methods: {
        openMenu() {
            const bars = $('.navbar-brand-mobile .hamburger');
            const header = $('.header-container, .header-container-dark');

            this.triggerMenu = !this.triggerMenu;

            if (this.triggerMenu) {
                bars.addClass('is-active');
                header.addClass('is-menu-collapse');
                $('body').addClass('disable-scroll');
            } else {
                bars.removeClass('is-active');
                header.removeClass('is-menu-collapse');
                $('body').removeClass('disable-scroll');
            }
        },
        listOfWinners() {
            if (loggedIn) {
                axios.get('/api/winner-list', config)
                    .then((r) => {
                        if (r.status === 200 && r.data.announced === true) {
                            this.isEnabled = true
                            this.filePath = r.data.file
                        } else {
                            this.isEnabled = false;
                            this.filePath = ''
                        }
                    })
                    .catch((r) => { })
            }
        }
    }
}