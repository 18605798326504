const EditProfileForm = {
    data() {
        return {
            profileEditAlert: false,
            profileAlert: false,
            profileFirstname: '',
            profileLastname: '',
            profilePhone: '',
            profilePassword: '',
            profilePasswordConfirm: '',
            profileAddress: '',
            profileShippingAddress: '',
            profileAlert: false,
            profileAlertMessage: '',
            EditPasswordError: false,
            EditPasswordErrorText: '',
            isEditSuccess: null,
            relogRequired: false
        }
    },
    created() {
        document.addEventListener('keyup', this.editProfileValidate);
        this.showUserData();
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.editProfileValidate);
    },
    methods: {
        showUserData() {
            if (loggedIn) {
                axios.get('/api/user/data', config)
                    .then((r) => {
                        const data = r.data;

                        this.profileFirstname = data.firstname;
                        this.profileLastname = data.lastname;
                        this.profilePhone = data.phone;
                        this.profileAddress = data.address;
                        this.profileShippingAddress = data.shipping_address;
                    })
                    .catch((error) => { })
            }
        },
        editProfile() {
            let params = {
                firstname: this.profileFirstname,
                lastname: this.profileLastname,
                address: this.profileAddress,
                shippingAddress: this.profileShippingAddress,
                phone: this.profilePhone,
                password: this.profilePassword == this.profilePasswordConfirm
            }

            this.isBusy = true;

            if (this.profilePassword === this.profilePasswordConfirm &&
                this.profilePassword !== '' && this.profilePasswordConfirm !== '' &&
                this.profileFirstname !== '' &&
                this.profileLastname !== '' &&
                this.profilePhone.length === 9 &&
                this.profileAddress !== '' &&
                this.profileShippingAddress !== '') {

                params.password = this.profilePassword;
                this.EditPasswordError = false;
                this.EditPasswordErrorText = '';

                axios.post('/api/user/profil', params, config)
                    .then((response) => {
                        this.isBusy = false;
                        this.editProfileSuccess()
                    })
                    .catch((error) => {
                        this.editProfileFailure(error.response)
                    })
            } else {
                params.password = '';
                this.isBusy = false
                return false;
            }

        },
        editProfileSuccess() {
            this.isBusy = false
            this.relogRequired = true
        },
        editProfileFailure(error) {
            this.isBusy = false
            this.isEditSuccess = false
            this.profileAlert = true

            this.profileAlertMessage = 'Coś poszło nie tak. Popraw błędy i spróbuj ponownie.'

            setTimeout(() => {
                this.profileAlert = false
                this.isEditSuccess = false
            }, 7000)
        },
        profilePhoneValidate($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }

            if (this.profilePhone.length >= 9) {
                $event.preventDefault();
                return false;
            }
        },
        editPassword() {
            const passwordConfirm = document.getElementById('confirm_password');

            if (this.profilePassword.length >= 5) {
                passwordConfirm.removeAttribute('disabled')
            } else {
                passwordConfirm.setAttribute('disabled', true)
            }

            if (this.profilePassword.length < 5 && this.profilePasswordConfirm.length > 0) {
                this.profilePassword;
                this.EditPasswordError = false;
            }

            if (this.profilePassword === '' && this.profilePasswordConfirm === '') {
                this.profilePassword;
                this.EditPasswordError = false;
                this.EditPasswordErrorText = '';
            }
        },
        editPasswordValidate() {
            if (this.profilePassword === '') {
                this.profilePassword;
                this.EditPasswordError = false;
                this.EditPasswordErrorText = '';
            }

            if (this.profilePasswordConfirm === this.profilePassword) {
                this.profilePassword;
                this.EditPasswordError = false;
                this.EditPasswordErrorText = '';

                if (this.profilePassword === '' || this.profilePasswordConfirm === '') {
                    this.profilePassword;
                    this.EditPasswordError = false;
                    this.EditPasswordErrorText = '';
                }
            } else {
                this.EditPasswordError = true;
                this.EditPasswordErrorText = 'Hasła się różnią.'
                return false;
            }
        },

    }
}